/**
 * Converts a Date, number, or string to the format "DD/MM/YYYY".
 * @param date - The input date in Date, number, or string format.
 * @returns The formatted date string in "DD/MM/YYYY" format.
 */
export function getFormatDate(date: Date | number | string): string {
  try {
    const newDate = new Date(date)

    if (isNaN(newDate.getTime())) {
      throw new Error("Invalid date")
    }

    const formattedDate = newDate.toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })

    return formattedDate
  } catch (error) {
    console.error("Error occurred:", error)
    return "" // or handle the error in an appropriate way for your use case
  }
}

/**
 * Converts a date string from "DD/MM/YYYY" format to "YYYY-MM-DD" format.
 * @param date - The input date string in "DD/MM/YYYY" format.
 * @returns The converted date string in "YYYY-MM-DD" format.
 */
export const dateFormatForDayjs = (date: string): string => {
  try {
    const parts = date.split("/")
    const year = parts[2]
    const month = parts[1]
    const day = parts[0]

    if (!year || !month || !day) {
      throw new Error("Invalid date format")
    }

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  } catch (error) {
    console.error("Error occurred:", error)
    return "" // or handle the error in an appropriate way for your use case
  }
}
